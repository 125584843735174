import axios from "axios";
import {host} from "../config/host"

axios.defaults.withCredentials = true

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: "LoginRequest",
    });

    const { data } = await axios.post(
      `${host}/api/v1/user/login`,
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: "LoginSuccess",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "LoginFailure",
      payload: error,
    });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "LogoutUserRequest",
    });

    await axios.post(`${host}/api/v1/user/logout`);

    dispatch({
      type: "LogoutUserSuccess",
    });
  } catch (error) {
    dispatch({
      type: "LogoutUserFailure",
      payload: "error"  
    });
  }
};

export const getUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "GetUserRequest",
    });

    const { data } = await axios.get(`${host}/api/v1/user/account`);

    dispatch({
      type: "GetUserSuccess",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "GetUserFailure",
      payload: error,
    });
  }
}
